<template>
  <form @submit.prevent="onSave">
    <ODNForm>
      <!-- Division -->
      <ODNFormField
        :label="$t('fields.division.label')"
        :error="isSubmitted && errors.divisionId ? $t('errors.required') : null"
      >
        <ion-select
          v-if="!divisionsLoading"
          v-model="form.divisionId"
          :placeholder="$t('fields.division.placeholder')"
          :cancelText="$t('buttons.cancel')"
          :okText="$t('buttons.validate')"
          @ionChange="onDivisionSelect"
        >
          <ion-select-option
            v-for="division in offline
              ? getOptionSet('divisions').items
              : divisions"
            :key="division.id"
            :value="division.id"
          >
            {{ division.name }}
          </ion-select-option>
        </ion-select>
      </ODNFormField>
      <!-- Area -->
      <ODNFormField
        :label="$t('fields.area.label')"
        :error="isSubmitted && errors.areaId ? $t('errors.required') : null"
      >
        <ion-select
          v-if="!areasLoading"
          v-model="form.areaId"
          :placeholder="$t('fields.area.placeholder')"
          :cancelText="$t('buttons.cancel')"
          :okText="$t('buttons.validate')"
        >
          <ion-select-option
            v-for="area in offline ? getOptionSet('areas').items : areas"
            :key="area.id"
            :value="area.id"
          >
            {{ area.name }}
          </ion-select-option>
        </ion-select>
      </ODNFormField>
      <!-- Ticket Type -->
      <ODNFormField
        v-if="
          profile.assignment && ['technician'].includes(profile.assignment.name)
        "
        :label="$t('fields.ticketType.label')"
        :error="
          isSubmitted && errors.ticketTypeId ? $t('errors.required') : null
        "
      >
        <ion-select
          v-if="!ticketTypesLoading"
          v-model="form.ticketTypeId"
          :placeholder="$t('fields.ticketType.placeholder')"
          :cancelText="$t('buttons.cancel')"
          :okText="$t('buttons.validate')"
        >
          <ion-select-option
            v-for="ticketType in offline
              ? getOptionSet('ticketTypes').items
              : ticketTypes"
            :key="ticketType.id"
            :value="ticketType.id"
          >
            {{ $t(`ticketTypes.${ticketType.name}`) }}
          </ion-select-option>
        </ion-select>
      </ODNFormField>
      <!-- Project -->
      <ODNFormField>
        <template #alt-label>
          {{ $t('fields.project.label') }}
        </template>
        <template #alt-content>
          <div class="odn-pay-4">
            {{
              selectedProject
                ? `${selectedProject.customer.name} - ${selectedProject.name}`
                : $t('labels.none')
            }}
          </div>
          <ion-button color="light" expand="block" @click="onModalProjects">
            <ion-icon slot="start" :icon="icons.search"></ion-icon>
            {{ $t('buttons.selectProject') }}
          </ion-button>
        </template>
      </ODNFormField>
      <!-- Product -->
      <ODNFormField
        v-if="!form.id"
        :error="isSubmitted && errors.productId ? $t('errors.required') : null"
      >
        <template #alt-label>
          {{ $t('fields.product.label') }}
        </template>
        <template #alt-content>
          <div class="odn-pay-4">
            {{ selectedProduct ? selectedProduct.name : $t('labels.none') }}
          </div>
          <ion-button color="light" expand="block" @click="onModalProducts">
            <ion-icon slot="start" :icon="icons.search"></ion-icon>
            {{ $t('buttons.selectProduct') }}
          </ion-button>
        </template>
      </ODNFormField>
      <!-- Omnighost scan (or) -->
      <ODNFieldUnit
        v-if="!form.id && !offline"
        v-model="form.unit"
        :survey-field="{
          name: $t('fields.unit.label'),
          isRequired: false,
        }"
        class="odn-mab-8"
      />
      <!-- Scenario -->
      <ODNFormField
        :error="isSubmitted && errors.scenarioId ? $t('errors.required') : null"
      >
        <template #alt-label>
          {{ $t('fields.scenario.label') }}
        </template>
        <template #alt-content>
          <div class="odn-pay-4">
            {{ selectedScenario ? selectedScenario.name : $t('labels.none') }}
          </div>
          <ion-button color="light" expand="block" @click="onModalScenarios">
            <ion-icon slot="start" :icon="icons.search"></ion-icon>
            {{ $t('buttons.selectScenario') }}
          </ion-button>
        </template>
      </ODNFormField>
      <!-- Contact name -->
      <ODNFormField
        :label="$t('fields.contactName.label')"
        label-position="floating"
      >
        <ion-input v-model="form.contactName"></ion-input>
      </ODNFormField>
      <!-- Contact phone -->
      <ODNFormField
        :label="$t('fields.contactPhone.label')"
        label-position="floating"
      >
        <ion-input v-model="form.contactPhone"></ion-input>
      </ODNFormField>
      <!-- Contact email -->
      <ODNFormField
        :label="$t('fields.contactEmail.label')"
        label-position="floating"
      >
        <ion-input v-model="form.contactEmail" type="email"></ion-input>
      </ODNFormField>
      <!-- Photo -->
      <ODNUploadImage
        v-model="ticketFile"
        :submitted="isSubmitted && errors.ticketFile"
      />
      <!-- Comment -->
      <ODNFormField
        :label="$t('fields.comment.label')"
        label-position="stacked"
      >
        <ion-textarea
          v-model="form.comment"
          :placeholder="$t('fields.comment.placeholder')"
          :rows="2"
        ></ion-textarea>
      </ODNFormField>
      <!-- Ticket status -->
      <ODNFormField
        :label="$t('fields.ticketStatus.label')"
        :error="
          isSubmitted && errors.ticketStatusId ? $t('errors.required') : null
        "
      >
        <ion-select
          v-if="!ticketStatusesLoading"
          v-model="form.ticketStatusId"
          :placeholder="$t('fields.ticketStatus.placeholder')"
          :cancelText="$t('buttons.cancel')"
          :okText="$t('buttons.validate')"
          @ionChange="onTicketStatusSelect"
        >
          <ion-select-option
            v-for="ticketStatus in offline
              ? getOptionSet('ticketStatuses').items
              : ticketStatuses"
            :key="ticketStatus.id"
            :value="ticketStatus.id"
          >
            {{ $t(`ticketStatuses.${ticketStatus.name}`) }}
          </ion-select-option>
        </ion-select>
      </ODNFormField>
      <!-- Resolved -->
      <ODNFormSwitch
        :label="$t('fields.resolved.label')"
        v-model="form.resolved"
        :disabled="form.ticketStatusId === 2"
        @toggle="onResolvedToggle"
      />
      <!-- Closing Photo -->
      <ODNUploadImage
        v-if="form.resolved && !ticketId"
        v-model="ticketResolvedFile"
        :title="$t('fields.resolvedPicture.label')"
        :submitted="isSubmitted && errors.ticketResolvedFile"
      />
      <!-- Fail reason -->
      <ODNFormField
        v-if="!form.resolved"
        :label="$t('fields.failReason.label')"
        :error="
          isSubmitted && errors.failReasonId ? $t('errors.required') : null
        "
      >
        <ion-select
          v-if="!failReasonsLoading"
          v-model="form.failReasonId"
          :placeholder="$t('fields.failReason.placeholder')"
          :cancelText="$t('buttons.cancel')"
          :okText="$t('buttons.validate')"
        >
          <ion-select-option
            v-for="failReason in offline
              ? getOptionSet('failReasons').items
              : failReasons"
            :key="failReason.id"
            :value="failReason.id"
          >
            {{ failReason.name }}
          </ion-select-option>
        </ion-select>
      </ODNFormField>
      <!-- Timer -->
      <div class="odn-mat-4">
        <ODNTimer
          v-model="form.duration"
          :is-full="true"
          :is-outline="true"
          :is-hidden="true"
        />
      </div>
      <!-- Actions -->
      <div class="odn-mat-16">
        <ion-button
          v-if="offline"
          type="submit"
          color="primary"
          :disabled="saving"
          expand="block"
        >
          {{ $t('buttons.save') }}
          <ion-icon slot="end" :icon="icons.save"></ion-icon>
        </ion-button>
        <ion-button
          v-else
          type="submit"
          color="primary"
          :disabled="saving"
          expand="block"
        >
          {{ $t('buttons.validate') }}
          <ODNSpinner v-if="saving" slot="end" />
          <ion-icon v-else slot="end" :icon="icons.checkmark"></ion-icon>
        </ion-button>
      </div>
    </ODNForm>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { Filesystem, Directory } from '@capacitor/filesystem';

import { Base64 } from 'js-base64';

import {
  IonInput,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonTextarea,
  modalController,
  toastController,
} from '@ionic/vue';
import { search, checkmark, qrCodeOutline, save } from 'ionicons/icons';

import ODNForm from '@c/odn-form.vue';
import ODNFormField from '@c/odn-form-field.vue';
import ODNFormSwitch from '@c/odn-form-switch.vue';
import ODNTimer from '@c/odn-timer';
import ODNFieldUnit from '@c/fields/odn-field-unit.vue';
import ODNUploadImage from '@c/odn-upload-image.vue';
import ODNSpinner from '@c/odn-spinner.vue';

import ODNModalSelect from '@m/odn-modal-select.vue';
import ODNModalTreeSelect from '@m/odn-modal-tree-select.vue';
import ODNModalGroupedSelect from '@m/odn-modal-grouped-select.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonInput,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonIcon,
    IonTextarea,
    ODNForm,
    ODNFormField,
    ODNFormSwitch,
    ODNTimer,
    ODNFieldUnit,
    ODNUploadImage,
    ODNSpinner,
  },
  props: {
    storeId: {
      type: Number,
      default: null,
    },
    ticketId: {
      type: Number,
      default: null,
    },
    offline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        id: null,
        storeId: parseInt(this.storeId),
        divisionId: null,
        areaId: null,
        ticketTypeId: null,
        projectId: null,
        scenarioId: null,
        productId: null,
        unit: null,
        ticketStatusId: null,
        failReasonId: null,
        contactName: null,
        contactPhone: null,
        contactEmail: null,
        duration: 0,
        comment: null,
        resolved: false,
      },
      ticketFile: null,
      ticketResolvedFile: null,
      isSubmitted: false,
      loading: false,
      saving: false,
      divisions: [],
      divisionsLoading: false,
      selectedDivision: null,
      areas: [],
      areasLoading: false,
      selectedArea: null,
      projects: [],
      projectsLoading: false,
      selectedProject: null,
      scenarios: [],
      scenariosLoading: false,
      selectedScenario: null,
      rawProducts: [],
      productsLoading: false,
      selectedProduct: null,
      globalScenarios: [],
      globalScenariosLoading: false,
      productCategoryScenarios: [],
      productCategoryScenariosLoading: false,
      ticketTypes: [],
      ticketTypesLoading: false,
      ticketStatuses: [],
      ticketStatusesLoading: false,
      failReasons: [],
      failReasonsLoading: false,
      icons: {
        search,
        checkmark,
        qrCodeOutline,
        save,
      },
      offlineTicket: {
        store: null,
        ticket: null,
        actions: [],
        upload: null,
      },
    };
  },
  computed: {
    ...mapState('auth', ['profile']),
    ...mapState('stores', {
      store: 'selectedStore',
    }),
    ...mapGetters('offline', ['getOptionSet', 'getSection']),
    errors() {
      let unit = {};
      try {
        unit = JSON.parse(this.form.unit);
      } catch (e) {
        return e;
      }
      return {
        divisionId: !this.form.divisionId,
        areaId: !this.form.areaId,
        scenarioId: !this.form.scenarioId,
        ticketTypeId:
          this.profile.assignment &&
          ['technician'].includes(this.profile.assignment.name)
            ? !this.form.ticketTypeId
            : false,
        ticketStatusId: !this.form.ticketStatusId,
        failReasonId: !this.form.resolved && !this.form.failReasonId,
        productId: unit && unit.uuid && !this.form.productId,
        ticketFile: !this.form.id && !this.ticketFile,
        ticketResolvedFile:
          !this.form.id && this.form.resolved && !this.ticketResolvedFile,
      };
    },
    availableScenarios() {
      if (this.selectedProduct) {
        return this.productCategoryScenarios;
      } else if (!this.selectedProduct && this.selectedProject) {
        return this.scenarios;
      }

      return this.globalScenarios;
    },
    products() {
      const groupedProducts = [];

      this.rawProducts.forEach(category => {
        if (
          !this.form.divisionId ||
          (this.form.divisionId &&
            category.division &&
            category.division.id === this.form.divisionId)
        ) {
          if (category.products) {
            groupedProducts.push({
              name: category.name,
              items: category.products.map(product => {
                return {
                  id: product.id,
                  name: product.name,
                  rootProductCategoryId: category.id,
                };
              }),
            });
          }

          category.children.forEach(subcategory => {
            if (subcategory.products) {
              groupedProducts.push({
                name: `${category.name} - ${subcategory.name}`,
                items: subcategory.products.map(product => {
                  return {
                    id: product.id,
                    name: product.name,
                    rootProductCategoryId: category.id,
                  };
                }),
              });
            }
          });
        }
      });

      return groupedProducts;
    },
  },
  created() {
    if (this.ticketId) {
      this.fetchTicket();
    }
    this.fetchProjects();
    this.fetchGlobalScenarios();
    this.fetchProducts();
    this.fetchDivisions();
    this.fetchAreas();
    this.fetchTicketTypes();
    this.fetchTicketStatuses();
    this.fetchFailReasons();
  },
  emits: ['saved', 'cancel'],
  methods: {
    ...mapActions('offline', ['saveOfflineTicket', 'saveOfflineTicketFile']),
    onDivisionSelect() {
      this.selectedProduct = null;
      this.form.productId = null;
      this.selectedScenario = null;
      this.form.scenarioId = null;
    },
    async onModalProjects() {
      const modal = await modalController.create({
        component: ODNModalSelect,
        componentProps: {
          title: this.$t('modals.projects'),
          items: this.projects,
          selected: this.form.projectId,
          formatting: ({ name, customer }) => {
            return `${customer.name} - ${name}`;
          },
        },
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.selectedProject = result;
        this.form.projectId = result.id;
        this.selectedScenario = null;
        this.form.scenarioId = null;
        this.fetchScenarios(result.id);
      }
    },
    async onModalScenarios() {
      const modal = await modalController.create({
        component: ODNModalTreeSelect,
        componentProps: {
          title: this.$t('modals.scenarios'),
          items: this.availableScenarios,
          selected: this.form.scenarioId,
          formatting: ({ name }) => {
            return `${name}`;
          },
        },
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.selectedScenario = result;
        this.form.scenarioId = result.id;
      }
    },
    async onModalProducts() {
      const modal = await modalController.create({
        component: ODNModalGroupedSelect,
        componentProps: {
          title: this.$t('modals.products'),
          groupedItems: this.products,
          selected: this.form.productId,
        },
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.selectedProduct = result;
        this.form.productId = result.id;
        this.selectedScenario = null;
        this.form.scenarioId = null;
        this.fetchProductCategoryScenarios(result);
      }
    },
    onTicketStatusSelect(evt) {
      if (evt.detail.value === 2) {
        this.form.resolved = true;
      }
    },
    onResolvedToggle(val) {
      if (val === true) {
        this.form.ticketStatusId = 2; // Close
      }
    },
    async fetchTicket() {
      this.loading = true;
      try {
        const ticket = (await APIService.get(`/tickets/${this.ticketId}`)).data;
        this.form.id = ticket.id;
        this.form.storeId = ticket.store.id;
        if (ticket.division) {
          this.form.divisionId = ticket.division.id;
        }
        if (ticket.area) {
          this.form.areaId = ticket.area.id;
        }
        if (ticket.ticketType) {
          this.form.ticketTypeId = ticket.ticketType.id;
        }
        if (ticket.project) {
          this.form.projectId = ticket.project.id;
          this.selectedProject = {
            id: ticket.project.id,
            customer: {
              name: ticket.project.customer.name,
            },
            name: ticket.project.name,
          };
          this.fetchScenarios(ticket.project.id);
        }
        if (ticket.product) {
          this.form.productId = ticket.product.id;
          this.selectedProduct = {
            id: ticket.product.id,
            name: ticket.product.name,
          };
          this.fetchProductCategoryScenarios(ticket.product);
        }
        this.form.scenarioId = ticket.scenario.id;
        this.selectedScenario = {
          id: ticket.scenario.id,
          name: ticket.scenario.name,
        };
        this.form.ticketStatusId = ticket.ticketStatus.id;
        this.form.contactName = ticket.contactName;
        this.form.contactPhone = ticket.contactPhone;
        this.form.contactEmail = ticket.contactEmail;
        this.form.duration = ticket.duration;
        this.form.comment = ticket.comment;
        this.form.resolved = ticket.resolved;
        if (ticket.failReason) {
          this.form.failReasonId = ticket.failReason.id;
        }

        this.$forceUpdate(); // To force update of ion-select / Temporary solution ?
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.ticket.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchProjects() {
      this.projectsLoading = true;
      try {
        if (this.offline) {
          const rawData = await Filesystem.readFile({
            path: 'projects.json',
            directory: Directory.Data,
          });

          this.projects = JSON.parse(Base64.decode(rawData.data));
        } else {
          this.projects = (
            await APIService.get(
              `/stores/${this.storeId}/projects?filter=light&ongoing=true`
            )
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.projects.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.projectsLoading = false;
      }
    },
    async fetchDivisions() {
      this.divisionsLoading = true;
      try {
        if (this.offline) {
          this.divisions = this.getOptionSet('divisions').items;
        } else {
          this.divisions = (
            await APIService.get(`/divisions?filter=light`)
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.divisions.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.divisionsLoading = false;
      }
    },
    async fetchAreas() {
      this.areasLoading = true;
      try {
        if (this.offline) {
          this.areas = this.getOptionSet('areas').items;
        } else {
          this.areas = (await APIService.get(`/areas?filter=light`)).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.areas.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.areasLoading = false;
      }
    },
    async fetchGlobalScenarios() {
      this.globalScenariosLoading = true;
      try {
        if (this.offline) {
          const rawData = await Filesystem.readFile({
            path: 'scenarios.json',
            directory: Directory.Data,
          });

          const scenarioTrees = JSON.parse(Base64.decode(rawData.data));

          if (scenarioTrees) {
            this.globalScenarios = scenarioTrees.filter(s => !s.project);
          }
        } else {
          this.globalScenarios = (
            await APIService.get(`/scenarios/trees/global`)
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.scenarios.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.globalScenariosLoading = false;
      }
    },
    async fetchScenarios(projectId) {
      this.scenariosLoading = true;
      try {
        if (this.offline) {
          const rawData = await Filesystem.readFile({
            path: 'scenarios.json',
            directory: Directory.Data,
          });

          const scenarioTrees = JSON.parse(Base64.decode(rawData.data));

          if (scenarioTrees) {
            this.scenarios = scenarioTrees.filter(
              s => s.project && s.project.id === projectId
            );
          }
        } else {
          this.scenarios = (
            await APIService.get(`/projects/${projectId}/scenarios/trees`)
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.scenarios.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.scenariosLoading = false;
      }
    },
    async fetchProductCategoryScenarios(product) {
      this.productCategoryScenariosLoading = true;
      try {
        if (this.offline) {
          const rawData = await Filesystem.readFile({
            path: 'scenarios.json',
            directory: Directory.Data,
          });

          const scenarioTrees = JSON.parse(Base64.decode(rawData.data));

          if (scenarioTrees) {
            this.productCategoryScenarios = scenarioTrees.filter(s => {
              return s.productCategories
                .map(pc => pc.id)
                .includes(product.rootProductCategoryId);
            });
          }
        } else {
          this.productCategoryScenarios = (
            await APIService.get(`/products/${product.id}/scenarios`)
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.scenarios.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.productCategoryScenariosLoading = false;
      }
    },
    async fetchProducts() {
      this.productsLoading = true;
      try {
        if (this.offline) {
          const rawData = await Filesystem.readFile({
            path: 'products.json',
            directory: Directory.Data,
          });

          this.rawProducts = JSON.parse(Base64.decode(rawData.data));
        } else {
          this.rawProducts = (
            await APIService.get(`/products?filter=light`)
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.products.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.productsLoading = false;
      }
    },
    async fetchTicketTypes() {
      this.ticketTypesLoading = true;
      try {
        if (!this.offline) {
          this.ticketTypes = (await APIService.get(`/ticket-types`)).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.ticketTypes.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.ticketTypesLoading = false;
      }
    },
    async fetchTicketStatuses() {
      this.ticketStatusesLoading = true;
      try {
        if (!this.offline) {
          this.ticketStatuses = (await APIService.get(`/ticket-statuses`)).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.ticketStatuses.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.ticketStatusesLoading = false;
      }
    },
    async fetchFailReasons() {
      this.failReasonsLoading = true;
      try {
        if (!this.offline) {
          this.failReasons = (
            await APIService.get(`/fail-reasons?filter=light`)
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.failReasons.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.failReasonsLoading = false;
      }
    },
    async onSave() {
      this.isSubmitted = true;

      const hasErrors = Object.values(this.errors).some(e => e);

      if (hasErrors) return;

      this.saving = true;

      // 1. Create the ticket and get newly created ID
      this.offlineTicket.store = {
        id: this.store.id,
        store: this.store.name,
        retailer: this.store.retailer.name,
      };

      if (this.ticketId) {
        try {
          await APIService.put(`/tickets/${this.ticketId}`, this.form);
          const toast = await toastController.create({
            message: this.$t('messages.ticket.put.success'),
            color: 'success',
            duration: 2000,
          });
          toast.present();
          this.$emit('saved', this.ticketId);
        } catch (err) {
          const toast = await toastController.create({
            message: this.$t('messages.ticket.put.error'),
            color: 'danger',
            duration: 2000,
          });
          toast.present();
        } finally {
          this.saving = false;
        }
      } else {
        try {
          let id;

          // Save ticket
          if (this.offline) {
            this.offlineTicket.ticket = this.form;
          } else {
            id = (await APIService.post(`/tickets`, this.form)).data.id;
          }

          // File upload
          if (this.ticketFile) {
            if (this.offline) {
              this.offlineTicket.upload = {
                file: this.ticketFile.file,
                type: this.ticketFile.file.type,
              };
            } else {
              const formData = new FormData();
              formData.append('file', this.ticketFile.file);
              await APIService.upload(`/tickets/${id}/files`, formData);
            }
          }

          // If ticket is already resolved
          if (this.form.resolved) {
            // Create closing action ...
            const closingAction = {
              ticketId: id,
              actionTypeId: 2, // Closing
            };

            const actionId = (
              await APIService.post(`/tickets/${id}/actions`, closingAction)
            ).data.id;

            // ... and upload file for this last action
            const actionFormData = new FormData();
            actionFormData.append('actionId', actionId);
            actionFormData.append('file', this.ticketResolvedFile.file);
            await APIService.upload(`/tickets/${id}/files`, actionFormData);
          }

          if (this.offline) {
            await this.saveOfflineTicket(this.offlineTicket);
          }

          const toast = await toastController.create({
            message: this.$t('messages.ticket.post.success'),
            color: 'success',
            duration: 2000,
          });
          toast.present();
          this.$emit('saved', id);
        } catch (err) {
          const toast = await toastController.create({
            message: this.$t('messages.ticket.post.error'),
            color: 'danger',
            duration: 2000,
          });
          toast.present();
        } finally {
          this.saving = false;
        }
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
