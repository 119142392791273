<template>
  <div class="odn-tree-select">
    <div
      class="odn-tree-select-item"
      :class="{
        'is-selected': item.id === selected,
        'is-newly-selected': item.id === newlySelected,
      }"
      @dblclick="onOpen"
      @click="handleClick(item)"
    >
      <div
        v-if="item.children.length > 0"
        class="odn-tree-select-item-icon"
        @click.stop="onOpen"
      >
        <ion-icon v-if="isOpen" :icon="icons.chevronDownOutline"></ion-icon>
        <ion-icon v-else :icon="icons.chevronForwardOutline"></ion-icon>
      </div>
      <div v-else class="odn-tree-select-item-icon">
        <ion-icon :icon="icons.readerOutline"></ion-icon>
      </div>
      <div class="odn-tree-select-item-label">
        {{ item.name }}
      </div>
      <div
        v-if="item.id === newlySelected && item.children.length > 0"
        class="odn-tree-select-item-hint"
        @click="onOpen"
      >
        <span v-if="isOpen">
          {{ $t('labels.collapse') }}
        </span>
        <span v-else>
          {{ $t('labels.expand') }}
        </span>
      </div>
    </div>
    <div v-show="isOpen" class="odn-tree-select-children">
      <ODNTreeSelect
        v-for="child in item.children"
        :key="child.id"
        :item="child"
        :selected="selected"
        :newly-selected="newlySelected"
        :handle-click="handleClick"
        @open="onChildOpen"
      />
    </div>
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import {
  chevronForwardOutline,
  chevronDownOutline,
  readerOutline,
} from 'ionicons/icons';

import ODNTreeSelect from '@/components/odn-tree-select';

export default {
  name: 'ODNTreeSelect',
  components: {
    IonIcon,
    ODNTreeSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    selected: {
      type: Number,
      default: null,
    },
    newlySelected: {
      type: Number,
      default: null,
    },
    handleClick: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      icons: {
        chevronForwardOutline,
        chevronDownOutline,
        readerOutline,
      },
    };
  },
  watch: {
    selected() {
      if (this.selected === this.item.id) {
        this.$emit('open');
      }
    },
  },
  mounted() {
    if (this.selected === this.item.id) {
      this.$emit('open');
    }
  },
  methods: {
    onOpen() {
      this.isOpen = !this.isOpen;
    },
    onChildOpen() {
      this.$emit('open');
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss">
.odn-tree-select {
  &-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.1s linear;

    &.is-selected {
      color: var(--ion-color-primary-contrast);
      background-color: var(--ion-color-primary);
    }

    &.is-newly-selected {
      color: var(--ion-color-tertiary-contrast);
      background-color: var(--ion-color-tertiary);
    }

    &-icon {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    &-label {
      margin-left: 12px;
      flex: 1;
    }

    &-hint {
      padding: 2px 8px;
      font-size: 0.8rem;
      background-color: rgba(#fff, 0.2);
      border-radius: 4px;
    }
  }

  &-children {
    margin-left: 24px;
  }
}
</style>
