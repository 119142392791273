<template>
  <div
    class="odn-timer"
    :class="{
      'odn-timer-full': isFull,
      'odn-timer-outline': isOutline,
      'odn-timer-hidden': isHidden,
    }"
  >
    <ion-icon :icon="hourglassOutline"></ion-icon>
    {{ formatDuration }}
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { hourglassOutline } from 'ionicons/icons';

export default {
  components: {
    IonIcon,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    isOutline: {
      type: Boolean,
      default: false,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timerInterval: null,
      hourglassOutline,
    };
  },
  computed: {
    formatDuration() {
      return this.$dayjs
        .duration(this.modelValue, 'seconds')
        .format('HH:mm:ss');
    },
  },
  created() {
    this.timerInterval = setInterval(this.incrementDuration, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timerInterval);
  },
  methods: {
    incrementDuration() {
      this.$emit('update:modelValue', this.modelValue + 1);
    },
  },
};
</script>

<style lang="scss">
.odn-timer {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: var(--ion-color-danger-contrast);
  background-color: var(--ion-color-danger);
  font-weight: bold;
  white-space: nowrap;
  border-radius: 4px;

  &-full {
    display: flex;
  }

  &-outline {
    color: var(--ion-color-danger);
    border: 1px solid var(--ion-color-danger);
    background-color: transparent;
  }

  &-hidden {
    display: none;
  }

  > ion-icon {
    margin-right: 8px;
  }
}
</style>
