<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ title }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="odn-modal-tree-select">
        <ODNTreeSelect
          v-for="item in items"
          :key="item.id"
          :item="item"
          :selected="selected"
          :newly-selected="currentlySelected ? currentlySelected.id : null"
          :handle-click="onSelect"
        />
        <div
          v-if="currentlySelected && currentlySelected.notes"
          class="odn-modal-tree-select-notes"
        >
          <div class="odn-modal-tree-select-notes-icon">
            <ion-icon :icon="icons.informationCircleOutline"></ion-icon>
          </div>
          <div v-html="currentlySelected.notes"></div>
        </div>
        <div class="ion-margin-top">
          <ion-button
            color="primary"
            :disabled="!currentlySelected"
            expand="block"
            @click="onValidate"
          >
            {{ $t('buttons.validate') }}
            <ion-icon slot="end" :icon="icons.checkmark"></ion-icon>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  modalController,
} from '@ionic/vue';
import {
  close,
  search,
  informationCircleOutline,
  checkmark,
} from 'ionicons/icons';

import ODNTreeSelect from '@/components/odn-tree-select';

export default {
  name: 'ModalTreeSelect',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    ODNTreeSelect,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      default: null,
    },
    formatting: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      searchText: '',
      currentlySelected: null,
      icons: {
        close,
        search,
        informationCircleOutline,
        checkmark,
      },
    };
  },
  methods: {
    dismiss() {
      modalController.dismiss();
    },
    onSelect(item) {
      this.currentlySelected = item;
    },
    onValidate() {
      modalController.dismiss(this.currentlySelected);
    },
    format(item) {
      if (this.formatting) {
        return this.formatting(item);
      } else {
        return item.name;
      }
    },
  },
};
</script>

<style lang="scss">
.odn-modal-tree-select {
  padding: 16px;

  &-notes {
    @include flex-default(flex-start, flex-start);

    margin-top: 16px;
    padding: 16px;
    color: var(--ion-color-light-contrast);
    background-color: var(--ion-color-light);
    border-radius: 4px;

    &-icon {
      font-size: 32px;
      margin-right: 8px;
    }
  }
}
</style>
