<template>
  <div class="odn-form-switch">
    <ODNField>
      <div class="odn-form-switch-content">
        <ODNLabel :label="label" />
        <ion-toggle
          @ionChange="onChange"
          :checked="modelValue"
          :disabled="disabled"
        />
      </div>
    </ODNField>
  </div>
</template>

<script>
import { IonToggle } from '@ionic/vue';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

export default {
  components: {
    IonToggle,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: Boolean,
    label: String,
    disabled: Boolean,
  },
  emits: ['update:modelValue', 'toggle'],
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', event.target.checked);
      this.$emit('toggle', event.target.checked);
    },
  },
};
</script>

<style lang="scss">
.odn-form-switch {
  margin-bottom: 8px;

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
